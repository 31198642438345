<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('loyalty.benefits.moments.overview')">
    <CCol cols="12" lg="12">
      <loyaltyBenefitsMoments :platformRoles="platformRoles" :platformPermissions="platformPermissions" :showHeader="true" :itemsPerPage="10"/>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';

import loyaltyBenefitsMoments from '@/components/loyalty/loyaltyBenefitsMoments.vue'

export default {
  name: 'BenefitsMoments',
  components: {
    loadingSpinner,
    noPermission,
    loyaltyBenefitsMoments
  },
  data() {
    return {
      platformPermissions: [],
      platformRoles: [],
      platformPermissionsLoaded: false
    }
  },
  methods: {
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPlatformRoles() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/roles')
      .then(res => {      
        this.platformRoles = res.data.data;
        // Update the platformRolesLoaded value
        this.platformRolesLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function(){  
    this.getPlatformPermissions();
    this.getPlatformRoles();
  }
}
</script>